import React from 'react';
import {
  Link,
  graphql,
  useStaticQuery,
} from 'gatsby';

const NewsFeed = (props) => {
  const newsFeedData = useStaticQuery(graphql`
    query {
      newsFeed: allMarkdownRemark(
        filter: {fields: {slug: {regex: "/news/"}}}
        sort: {order: DESC, fields: frontmatter___date}
      ) {
        edges {
          node {
            frontmatter {
              title
              date(formatString: "MM/DD/YYYY") # 08/23/2020
            }
            fields {
              slug
            }
            excerpt
          }
        }
      }
    }
  `);
  return (
    <div className="newsfeed--component">
      <h2 className="newsfeed-title">{props.title}</h2>
      <ul className="newsfeed content-list">
        {
          newsFeedData.newsFeed.edges.map((news, i) => (
            <li className="news-item" key={i}>
              <h3 className="news-item--title">
                <Link to={news.node.fields.slug}>{news.node.frontmatter.title}</Link>
              </h3>
              <strong>{news.node.frontmatter.date}</strong>
              <div dangerouslySetInnerHTML={{__html: news.node.excerpt}} />
            </li>
          ))
        }
      </ul>
    </div>
  );
};
NewsFeed.displayName = 'components/NewsFeed';
NewsFeed.defaultProps = {
  title: 'News',
};

export default NewsFeed;
