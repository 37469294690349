import React from 'react';
import { Link } from 'gatsby';

import Layout from "../components/layout"
import SEO from "../components/seo"
import NewsFeed from '../components/NewsFeed';

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <article>
      <h1>Welcome</h1>
      <p>Welcome to the new Epsilon Zeta Alumni Association website.</p>
      <p>We're still in the process of migrating documents, files, and photographs. Please stay tuned for news and further updates in the coming months.</p>
      <p>You may sign up for our mailing list or find us on social media <Link to="/find-us">here</Link>.</p>
    <NewsFeed title="Recent News" />
    </article>
  </Layout>
);

export default IndexPage;
